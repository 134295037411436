@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ropa+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cabin&display=swap);
.primary-btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0,0,0,0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  padding: 10px 20px;
}
.primary-btn:hover {
  background-color: #7fdbff;
  box-shadow: 0px 15px 20px rgba(46,229,157,0.4);
  color: #fff;
  transform: translateY(-7px);
}

.input {
  padding: 1rem;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #7fdbff;
  transition: all 0.3s;
}
.input:hover {
  border-color: #7fdbff;
}
.input:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24,144,255,0.2);
}

.icon--card {
  height: 20px;
  width: 20px;
  color: #fff;
}

.MuiToolbar-root {
  background-color: #0074d9;
  font-family: 'Orbitron', sans-serif !important;
}
.MuiToolbar-root img {
  height: 50px;
  width: 50px;
  margin-right: 0.75em;
}
.MuiToolbar-root svg {
  margin-right: 0.25em;
}
.MuiToolbar-root .tabs {
  position: absolute;
  right: 10px;
  width: 700px;
  display: flex;
  justify-content: center;
}
@media (min-width: 976px) {
  .MuiToolbar-root .tabs {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 975px) {
  .MuiToolbar-root .tabs {
    width: 40px;
  }
}
.MuiToolbar-root h5 {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px;
}
.MuiToolbar-root h3 {
  font-size: 16px;
}
@media (max-width: 500px) {
  .MuiToolbar-root h3 {
    font-size: 14px;
  }
}
.MuiPaper-root.MuiMenu-paper {
  top: 65px !important;
  left: calc(100vw - 700px) !important;
}
.test .MuiPaper-root.MuiMenu-paper {
  top: 65px !important;
  left: calc(100vw - 700px);
}
.MuiMenuItem-gutters {
  font-size: 14px !important;
}

.footer {
  background: #0b3653;
  color: #fff;
  padding: 1rem 0;
  z-index: 1;
}
.footer a {
  color: #fff;
}
.footer a:hover {
  opacity: 0.8;
  color: #fff;
}
.footer h3 {
  letter-spacing: -4px;
}
.footer__title {
  color: #f99268;
  margin-bottom: 1.5rem;
  font-size: 24px;
  font-family: 'Orbitron', sans-serif !important;
}
.footer__top {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 2rem 0;
}
.footer__logo {
  width: 25%;
}
.footer__contact {
  text-align: center;
  flex-grow: 1;
  flex-basis: 300px;
}
.footer__contact p {
  margin-bottom: 1rem;
}
.footer__social {
  flex-grow: 1;
  text-align: center;
  margin: 0 auto;
  flex-basis: 300px;
}
.footer__icons {
  display: flex;
  justify-content: center;
}
.footer__icons--icon {
  border: 2px solid #fff;
  border-radius: 3rem;
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer__copyright {
  display: flex;
  padding: 2rem 0;
  justify-content: space-around;
  width: 100%;
  border-top: 2px solid #224862;
}
.footer__copyright--divider {
  width: 2px;
  background: #224862;
  margin-left: 2rem;
}
.footer__copyright--links {
  justify-content: flex-end;
  display: flex;
}
.footer__copyright--links a {
  padding-left: 2rem;
}
@media screen and (max-width: 500px) {
  .footer__social,
  .footer__contact {
    margin: 1em 0;
  }
  .footer__copyright p {
    width: 70%;
    margin: 0 auto;
    text-align: center;
  }
}

body {
  overflow-x: hidden;
}
* {
  margin: 0;
  padding: 0;
}

.slider-container {
  width: 100vw;
  height: 700px;
}
.slider-container .each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 700px;
}
.slider-container h3 {
  font-family: 'Ropa Sans', sans-serif;
  background: #eee;
  opacity: 0.8;
  border-radius: 8px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 20px 30px;
  position: relative;
  top: -100px;
  margin: 0 auto;
  text-align: center;
}
.half-blocks-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  width: 100vw;
  font-family: 'Cabin', sans-serif;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.half-blocks-container .buy-graded-section {
  width: 50vw;
  background-color: #eee;
  height: 500px;
  flex-basis: 400px;
  flex-grow: 2;
  padding-top: 2em;
}
.half-blocks-container .buy-graded-section h3 {
  font-size: 28px;
  text-align: center;
  color: #000;
  padding-bottom: 1em;
}
.half-blocks-container .buy-graded-section .each-slide-2 {
  display: flex;
}
.half-blocks-container .buy-graded-section .each-slide-2 > img {
  margin: 0 auto;
  background-size: cover;
  height: 300px;
  width: 25%;
  border-radius: 8px;
  -webkit-filter: drop-shadow(0px 7px 26px rgba(38,39,52,0.26));
          filter: drop-shadow(0px 7px 26px rgba(38,39,52,0.26));
  border-radius: 15px;
}
.half-blocks-container .buy-graded-section .each-slide-2 > img:hover {
  -webkit-filter: drop-shadow(0px 7px 26px #ff4136);
          filter: drop-shadow(0px 7px 26px #ff4136);
  transform: translateY(-7px);
}
@media (min-width: 900px) and (max-width: 1200px) {
  .half-blocks-container .buy-graded-section .each-slide-2 > img {
    width: 40%;
  }
}
@media (max-width: 899px) {
  .half-blocks-container .buy-graded-section .each-slide-2 > img {
    width: 35%;
  }
}
.half-blocks-container .buy-graded-section button.primary-btn {
  margin-top: 4.25em;
}
.half-blocks-container .buy-graded-section button.primary-btn:hover {
  background-color: #ff4136;
}
.half-blocks-container .sell-section {
  width: 50vw;
  background-color: #ff4136;
  height: 500px;
  flex-basis: 400px;
  flex-grow: 2;
  color: #fff;
  padding-top: 2em;
}
.half-blocks-container .sell-section h3 {
  font-size: 28px;
  text-align: center;
  padding-bottom: 1em;
}
.half-blocks-container .sell-section form {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.half-blocks-container .sell-section form p {
  padding-bottom: 2.5em;
}
.half-blocks-container .sell-section form .input {
  width: 80%;
}
.half-blocks-container .sell-section form button.primary-btn {
  margin-top: 1em;
  position: relative;
  top: 30px;
}
.half-blocks-container .sell-section form button.primary-btn:hover {
  background-color: #0074d9;
}
.quarter-blocks-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  font-family: 'Cabin', sans-serif;
}
.quarter-blocks-container .yugioh,
.quarter-blocks-container .pokemon,
.quarter-blocks-container .pops,
.quarter-blocks-container .digimon {
  height: 350px;
  width: 25vw;
  flex-basis: 300px;
  flex-grow: 2;
}
.quarter-blocks-container .yugioh {
  background-color: #ffdc00;
}
.quarter-blocks-container .pokemon {
  background-color: #7fdbff;
}
.quarter-blocks-container .pops {
  background-color: #f012be;
}
.quarter-blocks-container .digimon {
  background-color: #7afa7e;
}
@media (min-width: 900px) and (max-width: 1200px) {
  .quarter-blocks-container .digimon img {
    left: 42.5%;
  }
  .quarter-blocks-container .digimon .quarter-block-description {
    background-color: #eee;
    width: 45%;
  }
}
.quarter-blocks-container img {
  height: 80px;
  width: 150px;
  position: relative;
  top: 20px;
  left: 30%;
}
.quarter-blocks-container svg {
  height: 30px;
  width: 60px;
  position: relative;
  top: 12.5px;
  padding: 0;
  margin: 0;
}
.quarter-blocks-container a {
  padding: 0;
  margin: 0;
}
.quarter-blocks-container .quarter-block-description {
  background-color: #eee;
  opacity: 0.7;
  padding: 10px 5px;
  border-radius: 8px;
  width: 85%;
  margin: 0 auto;
  position: relative;
  top: 40px;
}

@media (max-width: 500px) {
  .gallery-page {
    margin-bottom: 5em;
  }
}
@media (min-width: 500px) {
  .gallery-page {
    margin-bottom: 2em;
  }
}
.gallery-page svg {
  position: absolute;
  left: 0;
  top: 4em;
  z-index: -1;
}
@media (max-width: 500px) {
  .gallery-page svg {
    top: 3em;
  }
}
.gallery-page__main {
  width: 60%;
  margin: 0 auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 100;
  background: #fff;
  margin-top: 6em;
  font-family: 'Cabin', sans-serif;
  box-shadow: 0px 15px 20px #333;
  position: absolute;
  left: 20%;
  top: 55px;
  z-index: 5;
}
.gallery-page__main + div {
  margin: 0 auto;
}
@media (max-width: 500px) {
  .gallery-page__main {
    width: 95%;
    left: 2.5%;
  }
}
.gallery-cta {
  width: 300px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 20px;
  position: fixed;
  top: 70vh;
  left: 0;
  background-color: #e2e2ec;
  border-radius: 20px;
  opacity: 0.8;
  font-family: 'Ropa Sans', sans-serif;
  line-height: 20px;
  z-index: 9999;
}
.gallery-cta.fade-out {
  opacity: 0;
}
.gallery-cta span {
  float: right;
}
.gallery-cta button {
  margin-top: 1em;
}
.fade-out {
  animation: fadeOut ease 1s;
  -webkit-animation: fadeOut ease 1s;
  -moz-animation: fadeOut ease 1s;
  -o-animation: fadeOut ease 1s;
  -ms-animation: fadeOut ease 1s;
}
.search-box--container {
  height: 50px;
  width: 400px;
  background-color: #eee;
  opacity: 0.8;
  position: fixed;
  left: calc(50vw - 200px);
  border-radius: 8px;
  top: 4em;
  z-index: 10;
}
@media (max-width: 500px) {
  .search-box--container {
    top: 3em;
  }
}
.search-box--container .input {
  width: 92% !important;
}
@keyframes fadeOut {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 500px) {
  .sell-page {
    margin-bottom: 5em;
  }
}
@media (min-width: 500px) {
  .sell-page {
    margin-bottom: 2em;
  }
}
.sell-page svg {
  position: absolute;
  left: 0;
  top: 4em;
  z-index: -1;
}
@media (max-width: 500px) {
  .sell-page svg {
    top: 3em;
  }
}
.sell-page__main {
  width: 60%;
  margin: 0 auto;
  z-index: 100;
  background: #fff;
  margin-top: 6em;
  font-family: 'Cabin', sans-serif;
  box-shadow: 0px 15px 20px #333;
}
@media (max-width: 500px) {
  .sell-page__main {
    width: 95%;
  }
}
.sell-page__main input {
  width: 80%;
}
.sell-page__main h1 {
  text-align: center;
  padding: 15px 0 10px 0;
  font-family: 'Ropa Sans', sans-serif;
}
.sell-page__main h5 {
  text-align: center;
  font-size: 14px;
}
.sell-page__main img {
  height: 180px;
  width: 90%;
  margin: 15px 0;
  margin-left: 5%;
}
@media (max-width: 500px) {
  .sell-page__main img {
    width: 100%;
    height: 140px;
    margin-left: 0;
  }
}
.sell-page__main p {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
.sell-page__main form {
  padding-top: 2em;
  text-align: center;
}
.sell-page__main form input {
  margin: 0.5em 0;
}
.sell-page__main form button {
  margin: 1em 0 2em 0;
}

@media (max-width: 500px) {
  .calendar-page {
    margin-bottom: 5em;
  }
}
@media (min-width: 500px) {
  .calendar-page {
    margin-bottom: 2em;
  }
}
.calendar-page .calendar-page__main + svg {
  position: absolute;
  left: 0;
  top: 4em;
  z-index: -1;
}
@media (max-width: 500px) {
  .calendar-page .calendar-page__main + svg {
    top: 3em;
  }
}
.calendar-page__main {
  width: 60%;
  margin: 0 auto;
  z-index: 100;
  background: #fff;
  margin-top: 6em;
  font-family: 'Cabin', sans-serif;
  box-shadow: 0px 15px 20px #333;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
@media (max-width: 500px) {
  .calendar-page__main {
    width: 95%;
  }
}
.calendar-page__main h1 {
  text-align: center;
  padding: 15px 0 10px 0;
  font-family: 'Ropa Sans', sans-serif;
}
.calendar-page__main h5 {
  text-align: center;
  font-size: 14px;
  margin-bottom: 1em;
}
.calendar-page__main h2 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}
.calendar-page__main p {
  width: 90%;
  margin: 0 auto;
  text-align: left;
  font-size: 12px;
}
.calendar-page__main svg {
  height: 18px;
  width: 60px;
  position: relative;
  top: 0.5em;
}
.calendar-page__main thead tr {
  background-color: #add8e6;
}
.calendar-page__main th,
.calendar-page__main td {
  font-size: 12px;
}
.calendar-page + .footer {
  position: relative;
  top: 650px;
}
@media (max-width: 500px) {
  .calendar-page + .footer {
    position: relative;
    top: 1200px;
  }
}

@media (max-width: 500px) {
  .contact-page {
    margin-bottom: 5em;
  }
}
@media (min-width: 500px) {
  .contact-page {
    margin-bottom: 2em;
  }
}
.contact-page .contact-page__main + svg {
  position: absolute;
  left: 0;
  top: 4em;
  z-index: -1;
}
@media (max-width: 500px) {
  .contact-page .contact-page__main + svg {
    top: 3em;
  }
}
.contact-page__main {
  width: 60%;
  margin: 0 auto;
  z-index: 100;
  background: #fff;
  margin-top: 6em;
  font-family: 'Cabin', sans-serif;
  box-shadow: 0px 15px 20px #333;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
@media (max-width: 500px) {
  .contact-page__main {
    width: 95%;
  }
}
.contact-page__main__iframe {
  display: flex;
  justify-content: center;
}
.contact-page__main__iframe iframe {
  height: 300px;
  width: 300px;
  margin-bottom: 2em;
}
.contact-page__main h1 {
  text-align: center;
  padding: 15px 0 10px 0;
  font-family: 'Ropa Sans', sans-serif;
}
.contact-page__main h5 {
  text-align: center;
  font-size: 14px;
  margin-bottom: 1em;
}
.contact-page__main h2 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}
.contact-page__main p {
  width: 90%;
  margin: 0 auto;
  text-align: left;
  font-size: 12px;
}
.contact-page__main form {
  padding-top: 2em;
  text-align: center;
}
.contact-page__main form input {
  margin: 0.5em 0;
  width: 90%;
}
.contact-page__main form button {
  margin: 1em 0 2em 0;
}

.wishlist-page {
  width: 90%;
  left: 5%;
}
@media (max-width: 600px) {
  .wishlist-page {
    width: 100%;
    left: 0%;
  }
}
@media (max-width: 500px) {
  .wishlist-page {
    margin-bottom: 5em;
  }
}
@media (min-width: 500px) {
  .wishlist-page {
    margin-bottom: 2em;
  }
}
.wishlist-page svg {
  position: absolute;
  left: 0;
  top: 4em;
  z-index: -1;
}
@media (max-width: 500px) {
  .wishlist-page svg {
    top: 3em;
  }
}
.wishlist-page__main {
  width: 90%;
  margin: 0 auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 100;
  background: #fff;
  margin-top: 6em;
  font-family: 'Cabin', sans-serif;
  box-shadow: 0px 15px 20px #333;
  position: absolute;
  left: 5%;
  top: 55px;
  z-index: 5;
  padding-bottom: 10em;
}
@media (max-width: 600px) {
  .wishlist-page__main {
    width: 100%;
    left: 0%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    background: #fff;
    padding-bottom: 30em;
  }
}
.wishlist-page .photo-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.wishlist-page .photo-container div {
  max-width: 400px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  margin: 2em 1em;
}
@media (max-width: 600px) {
  .wishlist-page .photo-container div {
    margin: 12em 0em;
  }
}
.wishlist-page .photo-container img {
  max-width: 400px;
  max-height: 400px;
  flex-basis: 400px;
  position: relative;
}
@media (max-width: 600px) {
  .wishlist-page .photo-container img {
    max-width: 98vw;
    max-height: 98vh;
  }
}
.wishlist-page .photo-container div div {
  min-height: 40px;
  max-height: 40px;
  width: 80%;
  background: #111;
  opacity: 0.5;
  position: relative;
  top: 1em;
  left: 10%;
  color: #fff;
  text-align: center;
  border-radius: 8px;
}
@media (max-width: 500px) {
  .wishlist-page .photo-container div div {
    top: 12em;
  }
}
.wishlist-cta {
  width: 300px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 20px;
  position: fixed;
  top: 70vh;
  left: 0;
  background-color: #e2e2ec;
  border-radius: 20px;
  opacity: 0.8;
  font-family: 'Ropa Sans', sans-serif;
  line-height: 20px;
  z-index: 9999;
}
.wishlist-cta.fade-out {
  opacity: 0;
}
.wishlist-cta span {
  float: right;
}
.wishlist-cta button {
  margin-top: 1em;
}
.fade-out {
  animation: fadeOut ease 1s;
  -webkit-animation: fadeOut ease 1s;
  -moz-animation: fadeOut ease 1s;
  -o-animation: fadeOut ease 1s;
  -ms-animation: fadeOut ease 1s;
}
@keyframes fadeOut {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

.vault-page svg {
  position: absolute;
  top: 0;
}
.vault-page__main.gallery {
  background: #fff;
}
.vault-page__main.gallery h1 {
  text-align: center;
}
.vault-page__main.gallery button {
  border: none;
  background: #fff;
  width: 150px;
  text-align: right;
  font-size: 18px;
}
.vault-page__main.gallery button svg {
  position: relative;
  right: 0.5em;
  top: 0.25em;
}
.vault-page__main {
  width: 60%;
  margin: 0 auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 100;
  background: transperant;
  margin-top: 2em;
  font-family: 'Cabin', sans-serif;
  position: absolute;
  left: 20%;
  top: 55px;
  z-index: 5;
}
@media (max-width: 500px) {
  .vault-page__main {
    width: 95%;
    left: 2.5%;
  }
}
.vault-page__main__header {
  width: 100%;
  background: #fff;
  box-shadow: 0px 15px 20px #333;
}
.vault-page__main__header img {
  width: 375px;
  height: 300px;
  margin: 0 auto;
  position: relative;
  left: calc((100% - 375px) / 2);
}
.vault-page__main__header h1 {
  font-size: 40px;
  text-align: center;
  font-family: 'Ropa Sans', sans-serif;
}
.vault-page__main__header p {
  width: 80%;
  margin: 0 auto;
  padding: 1.5em 0;
  font-size: 18px;
}
@media (max-width: 500px) {
  .vault-page__main__header p {
    width: 95%;
    left: 2.5%;
  }
}
.vault-page__main .collection-card {
  width: 100%;
  margin: 0 auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 100;
  background: #fff;
  margin-top: 2em;
  font-family: 'Cabin', sans-serif;
  box-shadow: 0px 15px 20px #333;
  z-index: 5;
}
.vault-page__main .collection-card img {
  width: 100%;
  height: 350px;
}
.vault-page__main .collection-card h3 {
  font-size: 28px;
  text-align: left;
  font-family: 'Ropa Sans', sans-serif;
  padding: 0.5em 0;
  width: 80%;
  margin: 0 auto;
}
@media (max-width: 500px) {
  .vault-page__main .collection-card h3 {
    width: 95%;
  }
}
.vault-page__main .collection-card p {
  width: 80%;
  margin: 0 auto;
  padding: 1.5em 0;
  font-size: 18px;
}
@media (max-width: 500px) {
  .vault-page__main .collection-card p {
    width: 95%;
  }
}

@media (max-width: 500px) {
  .charity-page {
    margin-bottom: 5em;
  }
}
@media (min-width: 500px) {
  .charity-page {
    margin-bottom: 2em;
  }
}
.charity-page .charity-page__main + svg {
  position: absolute;
  left: 0;
  top: 4em;
  z-index: -1;
}
@media (max-width: 500px) {
  .charity-page .charity-page__main + svg {
    top: 3em;
  }
}
.charity-page__main {
  width: 60%;
  margin: 0 auto;
  z-index: 100;
  background: #fff;
  margin-top: 6em;
  font-family: 'Cabin', sans-serif;
  box-shadow: 0px 15px 20px #333;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.charity-page__main img.poster {
  width: calc(100vw * 0.6);
  height: 650px;
}
@media (max-width: 500px) {
  .charity-page__main {
    width: 95%;
  }
  .charity-page__main img.poster {
    width: 95vw;
    height: 650px;
  }
}
.charity-page__main h1 {
  text-align: center;
  padding: 15px 0 10px 0;
  font-family: 'Ropa Sans', sans-serif;
}
.charity-page__main p {
  width: 70%;
  margin: 0 auto 2em auto;
  text-align: left;
  font-size: 16px;
}
.charity-page__main p p {
  width: 100%;
}
.charity-page__main ul {
  width: 70%;
  margin: 0 auto 2em auto;
}
.charity-page__main ul li {
  list-style-type: none;
  margin-bottom: 0.25em;
}
.charity-page__main ul li strong {
  font-size: 18px;
  margin-right: 0.25em;
}
.MuiCardContent-root p {
  display: flex;
  width: 90%;
}
@media (max-width: 500px) {
  .MuiCardContent-root p {
    flex-wrap: wrap;
  }
}
.MuiCardContent-root p img {
  width: calc(100vw * 0.3);
  position: relative;
  right: calc(100vw * 0.03);
  height: 400px;
}
@media (max-width: 500px) {
  .MuiCardContent-root p img {
    position: inherit;
    margin: 0 auto 1em auto;
    width: calc(100vw * 0.8);
  }
}

header {
  background-color: #00f;
  color: #f00;
}
body {
  height: 100%;
}

